import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Container,
	FormFeedback,
	FormGroup,
	FormText,
	Form,
	Input,
	Label,
	Button,
	ButtonGroup,
	Col,
	Row,
	ListGroup,
	ListGroupItem,
	ListGroupItemHeading,
} from "reactstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import userRequests from "requests/userRequests";
import ReturnButton from "components/ReturnButton";
import { BxShow } from "components/Icon/show";

import "./style.css";

const EditUser = () => {

	const location = useLocation();
	const query = new URLSearchParams(location.search);

	const { state } = useLocation();
	const [showPass, setShowPass] = useState(false);

	const [user, setUser] = useState({});
	const [roles, setRoles] = useState([]);

	const navigate = useNavigate();


	useEffect(() => {

		let promises = [
			userRequests.getRoles(),
			userRequests.getUser(query.get("id"))
		];


		toast.promise(Promise.all(promises), {
			loading: "Carregando",
			success: "Pronto",
			error: (err) => "Erro - " + err.response.data.error_message,
		})
		.then(([ rRoles , rUser ]) => {
			setRoles(rRoles);
			setUser(rUser);
			console.log([ rRoles , rUser ])
		})
		.catch((err) => {
			const error = err.message;
			console.log(err);
		});

	}, []);

	const saveHandle = () => {

		let data = {
			...user
		}

		toast.promise(userRequests.editUser(data), {
			loading: "Salvando usuário...",
			success: "Usuário atualizado com successo!",
			error: (err) => "Erro - " + err.response.data.error_message,
		})
		.then((e) => {
			//navigate(`/usuarios`);
		})
		.catch((err) => {
			const error = err.message;
			console.log(err);
		});
	};

	const savePasswordHandle = () => {

		let data = {
			...user
		}

		toast.promise(userRequests.editUserPassword(data), {
			loading: "Salvando usuário...",
			success: "Senha atualizado com successo!",
			error: (err) => "Erro - " + err.response.data.error_message,
		})
		.then((e) => {
			//navigate(`/usuarios`);
		})
		.catch((err) => {
			const error = err.message;
			console.log(err);
		});
	};

	return (
		<div className="user-edit-container">
			<Container>
				<Card body  style={{padding: '25px !important'}}>
					<div className="user-edit-body">
						<CardHeader
							style={{
								display: "flex",
								alignItems: "center",
								margin: "0",
								padding: "0",
							}}
						>
							<p
								style={{
									margin: "0",
									padding: "0.5rem",
									display: "flex",
									height: "100%",
									alignItems: "center",
									fontSize: "15px",
									gap: "0.5rem",
								}}
							>
								Editar Usuário
							</p>
						</CardHeader>
						<CardBody
							style={{
								margin: "0 !important",
								padding: "0 !important",
								overflow: "overlay !important",
							}}
						>
							<Form
								onSubmit={(e) => {
									e.preventDefault();
									saveHandle();
								}}
							>
								{/* user name */}
								<FormGroup style={{ margin: "1rem 0 0 0 " }}>
									<Label for="credential.name">
										<b>Nome:</b>
									</Label>
									<Input
										value={user.name}
										onChange={(e) => {
											setUser({
												...user,
												name: e.target.value,
											});
										}}
									/>
								</FormGroup>
								{/* user email */}
								<FormGroup>
									<Label for="email">
										<b>Email:</b>
									</Label>
									<Input
										type="email"
										value={user.email}
										onChange={(e) => {
											setUser({
												...user,
												email: e.target.value,
											});
										}}
									/>
								</FormGroup>
								{/* user role */}
								<FormGroup>
									<Label for="role">
										<b>Perfil:</b>
									</Label>
									<Input
										type="select"
										onChange={(e) => {
											setUser({
												...user,
												role_id: e.target.value,
											});
										}}
									>
										<option value={null}><i>Selecione uma função</i></option>
										{roles.map(role => {
											return <option value={role.id} selected={role.id == user.role_id}>{role.name}</option>
										})}
									</Input>
								</FormGroup>

								<FormGroup>
									<Button
										type="submit"
										id="user-edit-button"
										style={{
											color: "white",
											background: "var(--COLOR_1)",
											gridColumn: 2,
										}}
									>
										Salvar
									</Button>

								</FormGroup>
							</Form>
						</CardBody>
					</div>
				</Card>
				<Card body style={{padding: '25px !important'}}>
					<div className="user-edit-body">
						<CardHeader
							style={{
								display: "flex",
								alignItems: "center",
								margin: "0",
								padding: "0",
							}}
						>
							<p
								style={{
									margin: "0",
									padding: "0.5rem",
									display: "flex",
									height: "100%",
									alignItems: "center",
									fontSize: "15px",
									gap: "0.5rem",
								}}
							>
								Senha Usuário
							</p>
						</CardHeader>
						<CardBody
							style={{
								margin: "0 !important",
								padding: "0 !important",
								overflow: "overlay !important",
							}}
						>
							<Form
								onSubmit={(e) => {
									e.preventDefault();
									savePasswordHandle();
								}}
							>
								{/* user passord */}
								<FormGroup>
									<Label for="password">
										<b>Senha:</b>
									</Label>
									<Input
										type="password"
										onChange={(e) => {
											setUser({
												...user,
												password: e.target.value,
											});
										}}
									/>
								</FormGroup>
								{/* user password confirmation */}
								<FormGroup>
									<Label for="password_confirmation">
										<b>Confirmação de Senha:</b>
									</Label>
									<Input
										type="password"
										onChange={(e) => {
											setUser({
												...user,
												password_confirmation: e.target.value,
											});
										}}
									/>
								</FormGroup>
								<FormGroup>
									<Button
										type="submit"
										id="user-edit-button"
										style={{
											color: "white",
											background: "var(--COLOR_1)",
											gridColumn: 2,
										}}
									>
										Salvar
									</Button>

								</FormGroup>
							</Form>
						</CardBody>
					</div>
				</Card>
			</Container>
		</div>
	);
};

export default EditUser;
