import "./index.css";

import toast from "react-hot-toast";
import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  Form,
  Col,
  Row,
  Container,
} from "reactstrap";

import authRequests from "requests/authRequests";
import Logo from "components/Logo";
import { BxShow } from "components/Icon/show";

const Login = ({ onLoginSuccess = () => {} }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [showPass, setShowPass] = useState();

  const handleLogin = (e) => {
    e.preventDefault();

    toast
      .promise(authRequests.login(email, password), {
        loading: "Realizando login",
        success: "Logado com sucesso!",
        error: "Email ou senha inválidos!",
      })
      .then((res) => {
        localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, res.data.token);
        window.location.assign("/");
      });
  };

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        userSelect: "none",
      }}
    >
      <Row
        className="align-items-center"
        style={{ maxWidth: "100vw", height: "33vmin" }}
      >
        <Container className="align-items-center">
          <Logo size={"3.5"} prevent={true} />
        </Container>
      </Row>
      <Row
        style={{
          display: "flex",
          flex: "1",
          alignItens: "center",
          justifyContent: "center",
          maxWidth: "100vw",
        }}
        className="col-12 col-lg-6"
      >
        <Card
          style={{ height: "200px", maxHeight: "270px", maxWidth: "100vw" }}
        >
          <CardHeader>Autenticação</CardHeader>
          <CardBody
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <Form className="col-12" onSubmit={(event) => handleLogin(event)}>
              <FormGroup>
                <Row className="mb-3">
                  <Col sm="12">
                    <Label>
                      <b>Email</b>
                    </Label>
                    <Input
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    ></Input>
                  </Col>
                </Row>
                <FormGroup row className="mb-3">
                  <Label for="credential.password">
                    <b>Senha</b>
                  </Label>

                  <div className="show-button-container">
                    <Input
                      type={showPass ? "text" : "password"}
                      placeholder="Senha"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <BxShow
                      show={showPass}
                      onClick={() => {
                        setShowPass(!showPass);
                      }}
                    />
                  </div>
                </FormGroup>

                <Row>
                  <Col sm="4">
                    <Button color="primary" type="submit">
                      Autenticar
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

export default Login;
