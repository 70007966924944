import api from "./api";

class UserRequest {


  async getUsers() {
    const res = await api.get("/users/list");
    return res.data;
  }

  async getRoles() {
    const res = await api.get("/users/roles/list");
    return res.data;
  }

  async getUser(id) {
    const res = await api.get(`/users/${id}/details`);
    return res.data;
  }
  

  async createUser(data) {
    const res = await api.post("/users/create", data);
    return res.data;
  }

  async editUser(data) {
    const res = await api.post("/users/edit", data);
    return res.data;
  }

  async editUserPassword(data) {
    const res = await api.post("/users/edit-password", data);
    return res.data;
  }


}

export default new UserRequest();
