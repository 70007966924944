import ReturnButton from "components/ReturnButton";
import { Get_Route } from "routes";
import { useLocation, useNavigate } from "react-router-dom";
import { Door } from "./Icon/door";

import "../assets/styles/app.css";
import authRequests from "requests/authRequests";
import toast from "react-hot-toast";

const Header = () => {
  const subRoute = true;
  const navigate = useNavigate();
  const location = useLocation();

  const logoutHandle = () => {
    toast
      .promise(authRequests.logout(), {
        loading: "loading",
        success: "success",
        error: (err) => console.error("error => " + err.message),
      })
      .then(navigate(0));
  };

  return (
    <div className={`header-container`}>
      <div className="left">
        {!Get_Route().head ? <ReturnButton className="return-button" /> : null}
        <h1>{Get_Route().name}</h1>
      </div>
      <div
        className="door"
        style={{ cursor: "pointer" }}
        onClick={() => logoutHandle()}
      >
        Encerrar
      </div>
    </div>
  );
};

export default Header;
