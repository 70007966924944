import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "../style.css";
import toast from "react-hot-toast";

import { Input, Button } from "reactstrap";

import integrationRequest from "requests/integrationRequest";
import credentialsRequests from "requests/credentialsRequests";
import brokerRequests from "requests/brokerRequests";

import TransVoyantCostType from "enums/TransVoyantCostType";
import CometrixCostType from "enums/CometrixCostType";
import IntegrationType from "enums/IntegrationType";

const Expenses = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

  const [credential, setCredential] = useState(null);
  const [credentialExpenses, setCredentialExpenses] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [expenseId, setExpenseId] = useState("");
  const [expenseType, setExpenseType] = useState("");

  const [types, setTypes] = useState([
    TransVoyantCostType.STANDARD,
    TransVoyantCostType.ADDITIONAL,
  ]);

  useEffect(() => {
    let promises = Promise.all([
      credentialsRequests.getCredentialByID(query.get("credential_id")),
      credentialsRequests.getExpensesByCredentialID(query.get("credential_id")),
      brokerRequests.getExpenses(),
    ]);

    toast
      .promise(promises, {
        loading: "Carregando",
        success: "Sucesso",
      })
      .then(
        ([cred, credExps, exps]) => {
          setCredential(cred);
          setCredentialExpenses(credExps);
          setExpenses(exps);
          setTypes(cred.integration?.expenses)
        },
        (error) => {}
      );
  }, []);

  function refreshExpenses() {
    toast
      .promise(
        credentialsRequests.getExpensesByCredentialID(
          query.get("credential_id")
        ),
        {
          loading: "Carregando",
          success: "Sucesso",
        }
      )
      .then(
        (resp) => {
          setCredentialExpenses(resp);
        },
        (error) => {}
      );
  }

  function handleAddExpense(e) {
    let data = {
      expense_id: expenseId,
      type: expenseType,
      credential_id: credential?.id,
    };

    toast
      .promise(credentialsRequests.addExpense(data), {
        loading: "Adicionando Despesa",
        success: "Sucesso",
      })
      .then(refreshExpenses, (error) => {
        //TODO HANDLE ERROR
      });
  }

  function handleUpdateExpense(credentialExpense, data) {
    Object.keys(data).map((k) => (credentialExpense[k] = data[k]));
    credentialExpense._changed = true;
    setCredentialExpenses([...credentialExpenses]);
  }

  function handleSaveExpense(credentialExpense) {
    let data = {
      credential_id: credentialExpense.credential_id,
      expense_id: credentialExpense.broker_expense_id,
      type: credentialExpense.expense_type,
    };

    toast
      .promise(credentialsRequests.addExpense(data), {
        loading: "Atualizando Despesa",
        success: "Sucesso",
      })
      .then(
        (response) => {
          credentialExpense._changed = false;
          setCredentialExpenses([...credentialExpenses]);
        },
        (error) => {}
      );
  }

  function handleDeleteExpense(credentialExpense) {
    let data = {
      credential_id: credentialExpense.credential_id,
      expense_id: credentialExpense.broker_expense_id,
      type: credentialExpense.expense_type
    };

    toast
      .promise(credentialsRequests.deleteExpense(data), {
        loading: "Excluindo Despesa",
        success: "Sucesso",
      })
      .then(refreshExpenses, (error) => {
        //TODO HANDLE ERROR
      });
  }

  return (
    <div className="add-container">
      <div>
        <h4>Configuração de despesas ({credential?.name})</h4>

        <div className="el" style={{ backgroundColor: "lightgrey" }}>
          <b>Adicionar Nova Despesa: </b>
          <Input
            type="select"
            value={expenseId}
            onChange={(e) => setExpenseId(e.target.value)}
          >
            <option value={""} disabled>
              Selecione uma despesa
            </option>
            {expenses.map((expense) => {
              return (
                <option value={expense.IDITEMDESPESA}>
                  {expense.NMITEMDESPESA} ({expense.CDITEMDESPESA})
                </option>
              );
            })}
          </Input>
          <Input
            type="select"
            value={expenseType}
            onChange={(e) => setExpenseType(e.target.value)}
          >
            <option value={""}>Selecione o tipo de despesa</option>
						{types.map(type => {
							return <option value={type.code}>{type.description}</option>
						})}
          </Input>
          <div>
            <Button onClick={handleAddExpense}>Adicionar</Button>
          </div>
        </div>
        <div className="el">
          <table>
            <thead>
              <tr>
                <td>Codigo</td>
                <td>Despesa</td>
                <td>Tipo</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {credentialExpenses?.map((credentialExpense) => {
                return (
                  <tr>
                    <td>{credentialExpense.expense?.CDITEMDESPESA}</td>
                    <td>{credentialExpense.expense?.NMITEMDESPESA}</td>
                    <td>
                      <Input
                        type="select"
                        value={credentialExpense.expense_type}
                        onChange={(e) =>
                          handleUpdateExpense(credentialExpense, {
                            expense_type: e.target.value,
                          })
                        }
                      >
                        <option value={""} disabled>
                          Selecione o tipo de despesa
                        </option>
                        {types.map(type => {
                          return <option value={type.code}>{type.description}</option>
                        })}
                      </Input>
                    </td>
                    <td>
                      {credentialExpense._changed && (
                        <Button
                          onClick={(e) => handleSaveExpense(credentialExpense)}
                        >
                          Salvar
                        </Button>
                      )}
                      <Button
                        onClick={(e) => handleDeleteExpense(credentialExpense)}
                      >
                        Remover
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Expenses;
