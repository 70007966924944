import { routes, Get_Route } from "routes";
import { Link, useRoutes, useNavigate, useLocation } from "react-router-dom";
import "assets/styles/app.css";

import Logo from "./Logo";

const Side = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const isActive = (path) => {
    let regex = "" + path;
    regex = new RegExp(regex);
    const result = regex.test(location.pathname);
    if (result) {
      return "isActive";
    }
    return path == location.pathname ? "isActive" : "";
  };

  return (
    <div className={`side-container`}>
      {/* <Logo theme="dark" /> */}
      <Logo size={2} theme="light" />
      <div className="nav-links-container">
        {routes.map((route, index) =>
          route.isVisible ? (
            <div
              key={index}
              className={"nav-links " + isActive(route.path)}
              onClick={() => navigate(route.path)}
            >
              {route.name}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default Side;
