import api from "./api";

class AuthRequests {
  async login(email, pass) {
    return await api.post(`/auth/login`, { email: email, password: pass });
  }

  async validateSession() {
    return await api.get(`/auth/session`);
  }

  async logout() {
    return await localStorage.removeItem("GREENWICH_SESSION_TOKEN");
  }
}

export default new AuthRequests();
