import { useNavigate } from "react-router-dom";

import logoDark from "../assets/logos/logo_dark.png";
import logoLight from "../assets/logos/logo_light.png";

import "../assets/styles/app.css";
const Logo = ({ prevent = false, theme = "light", size = 1 }) => {
  const navigate = useNavigate();
  return !prevent ? (
    theme == "dark" ? (
      <img
        style={{
          height: "auto",
          width: `calc(${size}*var(--logo-w))`,
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
        src={logoDark}
        className="logo"
        alt="Greenwich International"
      />
    ) : (
      <img
        style={{
          height: "auto",
          width: `calc(${size}*var(--logo-w))`,
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
        src={logoLight}
        className="logo"
        alt="Greenwich International"
      />
    )
  ) : theme == "dark" ? (
    <img
      style={{
        height: "auto",
        width: `calc(${size}*var(--logo-w))`,
        cursor: "default",
        userSelect: "none",
      }}
      src={logoDark}
      className="logo"
      alt="Greenwich International"
    />
  ) : (
    <img
      style={{
        height: "auto",
        width: `calc(${size}*var(--logo-w))`,
        cursor: "default",
        userSelect: "none",
      }}
      src={logoLight}
      className="logo"
      alt="Greenwich International"
    />
  );
};

export default Logo;
