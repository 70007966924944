import "./index.css";

import toast from "react-hot-toast";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Table,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";

import userRequests from "requests/userRequests";

const Users = () => {


	const [ users , setUsers ] = useState([]);

	useEffect(() => {

		toast.promise(userRequests.getUsers(), {
		  loading: "Carregando usuários...",
		  success: "Sucesso",
		  error: "Não foi possível carregar os dados",
		}).then((res) => setUsers(res));

	} , [])

	return (
		<div
		  style={{
			flexDirection: "column",
			padding: "15px",
			height: "100% ",
			overflow: "hidden",
		  }}
		>
		  <Card
			style={{
			  marginTop: "15px",
			  height: "calc(100% - 100px - 1rem)",
			  overflow: "hidden",
			}}
		  >
			<CardHeader>Usuários</CardHeader>
			<div style={{ overflowY: "overlay" }}>
			  <CardBody>
				<Link to="/usuarios/cadastrar">Adicionar</Link>
				<Table hover striped size="sm">
				  <thead>
					<tr
					  style={{
						position: "sticky",
						top: "10",
						background: "white",
						zIndex: "100",
					  }}
					>
					  <th>Nome</th>
					  <th>Email</th>
					  <th>Função</th>
					  <th>Cadastro</th>
					  <th></th>
					</tr>
				  </thead>
				  <tbody style={{}}>
					{users?.map((user) => {
					  return (
						<tr key={user?.id}>
						  <td>{user?.name}</td>
						  <td>{user?.email}</td>
						  <td>{user?.role?.name}</td>
						  <td>{user?.created_at}</td>
						  <td><Link to={`/usuarios/editar?id=${user?.id}`}>editar</Link></td>
						</tr>
					  );
					})}
				  </tbody>
				</Table>
			  </CardBody>
			</div>
			<CardFooter></CardFooter>
		  </Card>
		</div>
	  );


};

export default Users;
