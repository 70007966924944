import "./index.css";

import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Table,
  UncontrolledDropdown,
} from "reactstrap";

import customerRequest from "requests/customerRequest";
import dateHelper from "helpers/dateHelper";
import Loading from "components/Loading";

const ListCustomers = () => {
  const navigate = useNavigate();
  const [urlParameters] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [credential, setCredential] = useState([]);

  useEffect(() => {
    const credentialId = urlParameters.get("access_id");

    customerRequest.getCustomersByCredential(credentialId).then((response) => {
      setLoading(false);
      setCredential(response);
    });
  }, []);

  const handleNewCustomer = () => {
    const credentialId = urlParameters.get("access_id");

    navigate({
      pathname: "/integrations/customers/add",
      search: `?access_id=${credentialId}`,
    });
  };

  const handleCustomerDetails = (customerId) => {
    navigate({
      pathname: "/integrations/customers/details",
      search: `?customer_id=${customerId}`,
    });
  };

  const handleInactivateCustomer = (customer) => {
    toast
      .promise(customerRequest.inactivateCustomer(customer?.id), {
        loading: "Inativando usuário",
        success: "Usuário inativado com sucesso",
        error: "Não foi possível inativar o usuário",
      })
      .then((response) => {
        customer.inactivated_at = response?.inactivated_at;
        customer.is_active = response?.is_active;
        console.log({ customer, response });
        setCredential({ ...credential });
      });
  };

  return (
    <div className="container">
      <div className="mb-3">
        <Button color="primary" onClick={handleNewCustomer}>
          Novo cliente
        </Button>
      </div>

      <Card>
        {loading ? (
          <CardBody className="loading-container">
            <Loading message="Buscando clientes..." />
          </CardBody>
        ) : (
          <>
            <CardHeader>
              {credential &&
                `Clientes vinculados a integração: ${
                  credential?.integration?.name ?? ""
                } - ${credential?.name ?? ""}`}
            </CardHeader>
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>#ID</th>
                    <th>Código Broker</th>
                    <th>CNPJ</th>
                    <th>Nome Cliente</th>
                    <th>QTD de serviços vinculados</th>
                    <th>Data de criação</th>
                    <th>Ativo</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {credential?.customers?.map((customer) => (
                    <tr key={customer?.id}>
                      <td>{customer?.id}</td>
                      <td>{customer?.broker_customer?.CDPESSOA}</td>
                      <td>{customer?.broker_customer?.CNPJ}</td>
                      <td>{customer?.broker_customer?.NMPESSOA}</td>
                      <td style={{ textAlign: "center" }}>
                        {customer?.services?.length}
                      </td>
                      <td>
                        {dateHelper
                          .formatDateWithHours(customer?.created_at)
                          ?.toString()}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Input
                          type="checkbox"
                          checked={customer?.is_active}
                          onChange={() => handleInactivateCustomer(customer)}
                        />
                      </td>

                      <td>
                        <Button
                          onClick={() => handleCustomerDetails(customer?.id)}
                          size="sm"
                          color="primary"
                        >
                          Detalhes
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </>
        )}
      </Card>
    </div>
  );
};

export default ListCustomers;
